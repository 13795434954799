require.undef('buildTemplateInitializer');
define('buildTemplateInitializer', ['viewData', 'jquery', 'templateForm', 'templateData', 'perspectiveHotspotPreview', 'review', 'loadedTemplates'],
	function (viewData, $, templateForm, templateData, perspectiveHotspotPreview, review, loadedTemplates) {
		'use strict';

		var zoomViewSelector = '.preview-container .zoom-view';
		var perspectiveHotspotPreviewTimer = 0;
		var reviewTimer = 0;
        var pageLoad = true;

		var initializeBuildTemplate = function () {
			var savedBuild = viewData.SavedBuild;

			if (!savedBuild)
				return;

			$('.project-ui').addClass('build-template');

          

            // initialize the hotspots in the saved build
            savedBuild.Hotspots.forEach(function (hotspot) {
                initializeHotspot(hotspot);
            });


            
            // not the ideal way to do this.  Waiting for all hotspots field to load on page load.  Then apply the disabled hotspot feature
            $(document).ajaxStop(function () {
                if (pageLoad)  {
                    //get hotspot ids to disable
                    var hotspotsToDisable = [];
                    // get all hotspots from disabled perspective.  ---------> Disabling perspectives was removed per Becca.  2-21-18
                    for (var i = 0; i < savedBuild.DisabledPerspectives.length; i++)
                    {
                        for (var j = 0; j < viewData.Project.Perspectives.length; j++)
                        {
                            if (savedBuild.DisabledPerspectives[i] == viewData.Project.Perspectives[j].ID)
                            {
                                for (var k = 0; k < viewData.Project.Perspectives[j].Hotspots.length; k++)
                                {
                                    hotspotsToDisable.push(viewData.Project.Perspectives[j].Hotspots[k].ID);
                                }
                            }
                        }
                    }
                    // get all disabled hotspot IDs
                    for (var i = 0; i < savedBuild.DisabledHotspots.length; i++)
                    {
                        hotspotsToDisable.push(savedBuild.DisabledHotspots[i]);
                    }
                    var uniqueHotspotIDs = hotspotsToDisable.filter(function (elem, index, self) {
                        return index === self.indexOf(elem);
                    })

                    hotspotsToDisable.forEach(function (hotspotId) {
                        DisableHotspot(hotspotId);
                    });
                    pageLoad = false;
                }
            });

        };


        var DisableHotspot = function (hotspotId)
        {
            var $hotspot = $('.hotspot-view[data-id=' + hotspotId + ']');
            $hotspot.find('.menu-tab').removeClass('locked-in').addClass('locked-out');
            var $hotSpotField = $hotspot.find('.template-group-content .field');
            $hotSpotField.each(function (o, i) {
                var $this = $(this);
                $this.find('.field-option-text-control').addClass('read-only');
                $this.find('.field-option-color-control').addClass('read-only');
                $this.find('input').attr('readonly', 'readonly');
            });

            var $disabledOverlay = $('<div>').addClass('disabled-hotspot-overlay').html('Location has been locked by your department manager');
            $hotspot.append($disabledOverlay);                        
        }



		var initializeHotspot = function (hotspot) {

			// get the hotspot element
			var $hotspot = $('.hotspot-view[data-id=' + hotspot.ID + ']');

			// get the hotspot preview element
			var $hotspotPreview = $('.perspective-view .hotspot[data-hotspotid=' + hotspot.ID + ']');
			$hotspotPreview.addClass('build-template-related');

			// get the template group
			var $templateGroup = $hotspot.find('.template-group[data-id=' + hotspot.TemplateGroupID + ']');

			// mark the hotspot applied
			$templateGroup.parents('.hotspot-view').addClass('applied build-template-related tabcount-1').removeClass('tabcount-2 tabcount-3 tabcount-4 tabcount-5 tabcount-6');

			// activate the appropriate tab
			$templateGroup.parents('.menu-tab').addClass('open applied locked-in');

			// lock out sibling tabs
			$templateGroup.parents('.menu-tab').siblings('.menu-tab').remove();

			// activate the appropriate template group
			$templateGroup.addClass('open').addClass('open applied locked-in');

			// lock out sibling template groups
			$templateGroup.siblings().remove();

			// hide the other template groups
			$templateGroup.parents('.menu-tab-content').addClass('num-groups-1').removeClass('num-groups-2 num-groups-3 num-groups-4 num-groups-5 num-groups-6 num-groups-7 num-groups-8 num-groups-9 num-groups-10');

			// get the template form container
			var $templateFormContainer = $templateGroup.find('.template-form');

			// load the templates
			templateData.getTemplate(hotspot.Template.ID).done(function (template) {
                template.Price = viewData.SavedBuild.Hotspots[0].Template.Price;
                template.Price2 = viewData.SavedBuild.Hotspots[0].Template.Price2;

                // load the template into the library 
				loadedTemplates.loadTemplate(template);

				// initialize template form
				templateForm.initializeForm($templateFormContainer, template);

				// change the template button text
				var $templateSelectButton = $templateGroup.find('.template-button-container .select-template-button');
				$templateSelectButton.html('Change ' + $templateSelectButton.attr('data-label'));

				// remove the overlay from the previews
				var $zoomView = $(zoomViewSelector);
				$zoomView.removeClass('customizing').addClass('customizing');

				// initialize the saved build values
				applySavedBuildValuesToForm($templateFormContainer, hotspot);

				// request the previews for the hotspots in the perspective preview
				//showPerspectiveHotspotPreviews();

				// initialize the review section
                refreshReviewSection();

                // if there is at least 1 hotspot applied than show the review content instead of the project help text
                if ($('.hotspot-content-container .hotspot-view.applied').length > 0) {
                    $('.menu .project-help-text').addClass('hide');
                    $('.menu .review-container').removeClass('hide');
                    $('.menu .navigation-wrapper').addClass('hide');
                    $('.menu .template-form').addClass('hide');
                    $('.menu .navigation-wrapper').addClass('hide');
                    $('.menu .review-container').removeClass('hide');
                }

                // added for responsive
                $('.project-ui').addClass('active-fields');
			});
        };




        



		var applySavedBuildValuesToForm = function ($formContainer, hotspot) {
			hotspot.Fields.forEach(function (field) {
				var $field = $formContainer.find('.field[data-id=' + field.UUID + ']');
				if (field.ActiveOptionUUID)
					$field.find('.field-option-select').val(field.ActiveOptionUUID).change().parents('.option-select-dropdown-container').addClass('read-only');
				var $fieldOption = $field.find('.field-options .field-option[data-id=' + field.ActiveOptionUUID + ']');
                if ([2, 3, 4, 5, 6, 7, 8, 9, 13, 14].indexOf(field.Type) != -1) {	// text field type
                    if (!field.AllowFontChoice)
                    {
                        if (field.SelectedOption.ActiveFormatUUID)
                            $fieldOption.find('.text-format-select').val(field.SelectedOption.ActiveFormatUUID).change().parents('.format-select-dropdown-container').addClass('read-only');
                    }					
                    if (field.Text) {
                        $fieldOption.find('.Text-field .customizable-item-wrapper').addClass('active');
                        $fieldOption.find('.Text-field').addClass('read-only').find('input.field-option-text-input').val(field.Text).prop('readonly', true).change();
                    }
					if (field.ColorID)
						$fieldOption.find('.Color-field').addClass('read-only').find('input').val(field.ColorID).prop('readonly', true).change();
					if (field.OutlineColorID)
						$fieldOption.find('.OutlineColor-field').addClass('read-only').find('input').val(field.OutlineColorID).prop('readonly', true).change();
					if (field.ShadowColorID)
						$fieldOption.find('.ShadowColor-field').addClass('read-only').find('input').val(field.ShadowColorID).prop('readonly', true).change();
				}
				else if (field.Type == 1) {	// image field type
                    if (field.ImageID) {
                        $field.addClass('saved-build-first-view');
                        $fieldOption.find('.Image-field').addClass('read-only').find('input').val(field.ImageID).prop('readonly', true).change();
                    }
                }
                else if (field.Type == 11) {	// custom select options - radiobutton list or checkbox group
                    for (var i = 0; i < field.SelectedOptions.length; i++) {
                        $fieldOption.find('.CustomSelectOptions-field input[data-sku="' + field.SelectedOptions[i].Sku + '"]').prop("checked", true).change();
                    }
                    if (field.SelectedOptions.length > 0)
                    {
                        $fieldOption.find('.CustomSelectOptions-field input').addClass('read-only').prop('disabled', true).change();
                    }               
                }
                else if (buildField.Type === 12) {// custom image         
                    $fieldOption.find('.custom-image-content-wrapper input.custom-image-id').val(buildField.CustomImage.CloudinaryId);
                    $fieldOption.find('.custom-image-content-wrapper input.custom-image-version').val(buildField.CustomImage.Version);
                }
				else if (buildField.Type === 15) {// // salesperson/NS sku   
					// sku 1
					$fieldOption.find('.NS-Sku1-Description').html(buildField.NetSuiteSalesPersonData.Sku1Description);
					$fieldOption.find('.NS-Sku1-Description-Hidden').html(buildField.NetSuiteSalesPersonData.Sku1Description);
					$fieldOption.find('.NS-Sku1-Price').val(buildField.NetSuiteSalesPersonData.Sku1Price);
					$fieldOption.find('.NS-Sku1-Price-Hidden').val(buildField.NetSuiteSalesPersonData.Sku1Price);
					$fieldOption.find('.NS-Sku1-Cost').val(buildField.NetSuiteSalesPersonData.Sku1Cost);
					$fieldOption.find('.NS-Sku1-Cost-Hidden').val(buildField.NetSuiteSalesPersonData.Sku1Cost);
					$fieldOption.find('.NS-Sku1-Hidden').val(buildField.NetSuiteSalesPersonData.Sku1);
					$fieldOption.find('.NS-Sku1').val(buildField.NetSuiteSalesPersonData.Sku1);
					$fieldOption.find('.field-option-netsuite-control').addClass('valid-NS-sku');

					// sku 2
					$fieldOption.find('.netSuite-sku2-option-wrapper').removeClass('hide');
					if (buildField.NetSuiteSalesPersonData.Sku2 != null && buildField.NetSuiteSalesPersonData.Sku2 != '') {
						$fieldOption.find('#NetSuite-Sku2-Option').click();
						$fieldOption.find('.NS-Sku2-Description').html(buildField.NetSuiteSalesPersonData.Sku2Description);
						$fieldOption.find('.NS-Sku2-Description-Hidden').html(buildField.NetSuiteSalesPersonData.Sku2Description);
						$fieldOption.find('.NS-Sku2-Price').val(buildField.NetSuiteSalesPersonData.Sku2Price);
						$fieldOption.find('.NS-Sku2-Price-Hidden').val(buildField.NetSuiteSalesPersonData.Sku2Price);
						$fieldOption.find('.NS-Sku2-Cost').val(buildField.NetSuiteSalesPersonData.Sku2Cost);
						$fieldOption.find('.NS-Sku2-Cost-Hidden').val(buildField.NetSuiteSalesPersonData.Sku2Cost);
						$fieldOption.find('.NS-Sku2-Hidden').val(buildField.NetSuiteSalesPersonData.Sku2);
						$fieldOption.find('.NS-Sku2').val(buildField.NetSuiteSalesPersonData.Sku2);
						$fieldOption.find('.field-option-netsuite-control').addClass('valid-NS-sku');
					} else {
						$fieldOption.find('.NS-Sku2-Cost').val('');
					}
				}
            });
            // Notes field
            if (hotspot.Notes != undefined && hotspot.Notes != null && hotspot.Notes != '') {
                $formContainer.find('.field-notes textarea').attr('readonly', 'readonly').text(hotspot.Notes).addClass('read-only');
            }
		};

		var showPerspectiveHotspotPreviews = function () {
			clearTimeout(perspectiveHotspotPreviewTimer);
			perspectiveHotspotPreviewTimer = setTimeout(function () {
				perspectiveHotspotPreview.showPerspectiveHotspotPreviews();
			}, 700);
		};

		var refreshReviewSection = function () {
			clearTimeout(reviewTimer);
			reviewTimer = setTimeout(function () {
				review.refreshReviewUI();
			}, 700);
		};

		return {
			initializeBuildTemplate: initializeBuildTemplate
		};
	});
// require.undef removes the existing hotspotPreview definition so that it can be redefined immediately afterwards
require.undef('hotspotPreview');
// another important module that handles the requesting, loading and rendering of preview images for hotspots
define('hotspotPreview',
	['messaging', 'http', 'viewData', 'templateOptionsView', 'hotspotFormStateReader', 'menu', 'loadedTemplates', 'bottomBar'], function
	(messaging, http, viewData, templateOptionsView, hotspotFormStateReader, menu, loadedTemplates, bottomBar) {
	'use strict';

	// global vars for the module and constant dom selectors
	var projectUISelector = '.project-ui';
	var previewSelector = '.preview-container .preview';
	var zoomViewSelector = '.preview-container .zoom-view';
	var previewImageContainer = '.preview-container .zoom-preview-container';
	var currentHotspotSelector = '.preview-container .current-hotspot';
	var zoomOutLink = '.zoom-out-link';
	var showGuideLink = '.toggle-guide-link';
	var previewTimer = 0;
	var currentPerspective = {};
	var currentHotspot = {};
	var currentTemplate = {};
	var showGuide = false;
	var ajaxRequest = -1;
	var zoomedIn = false;

	// initialization function that subscribes to numerous pubsub topics and sets up some click handlers
	var init = function () {
		messaging.hotspotSelected.subscribe(hotspotSelected);
		messaging.hotspotPreviewRequested.subscribe(hotspotPreviewRequested);
		messaging.hotspotTemplateSelected.subscribe(hotspotTemplateSelected);
		messaging.applyPreview.subscribe(applyPreview);

		$('.customization-buttons .use-design').click(previewButtonClicked);
		$('.customization-buttons .use-text').click(previewButtonClicked);
		$('.customization-buttons .use-upload').click(previewButtonClicked);

		$(showGuideLink).click(showGuideLinkClicked);
	};

	// zoom out link click handler
	var zoomOutLinkClicked = function () {
		zoomedIn = false;
		$(previewSelector).removeClass('zoomed');
		$(projectUISelector).removeClass('perspective-mode hotspot-mode').addClass('perspective-mode');
		messaging.zoomedOut.publish();
	};

	// show preview guid link click handler
	var showGuideLinkClicked = function () {
		// this click handler is basically adds toggle functionality for field guides
		showGuide = !showGuide;
		var $showGuide = $(showGuideLink);
		if (showGuide) {
			// change the text of the link
			$showGuide.html('Hide Guide');
			bottomBar.guideDisabled();
		}
		else {
			// change the text of the link
			$showGuide.html('Show Guide');
			bottomBar.guideEnabled();
		}
		// request an updated hotspot preview
		hotspotPreviewRequested();
	};

	// hotspot selected handler
	var hotspotSelected = function (data) {
		// set private vars for this module
		zoomedIn = true;
		$(previewImageContainer).css('background-image', 'none');
		currentPerspective = data.perspective;
		currentHotspot = data.hotspot;
		// make the dom changes to zoom in the info
		$(previewSelector).removeClass('zoomed').addClass('zoomed');
		$(projectUISelector).removeClass('perspective-mode hotspot-mode').addClass('hotspot-mode');
		// request an updated hotspot preview
		hotspotPreviewRequested();
		$(currentHotspotSelector).html('[ Currently Designing: ' + data.hotspot.Label + ' ]');
		// gets the currently loaded template
		var template = getCurrentTemplate();
		if (template == null)
			$(zoomViewSelector).removeClass('customizing');
		else
			$(zoomViewSelector).addClass('customizing');
	};

	// this is a typical function that keeps hotspot preview requests to a minimum
	var hotspotPreviewRequested = function () {
		// if the ajaxRequest object was saved, then abort it (doesn't always do anything)
		if (ajaxRequest != -1) {
			ajaxRequest.abort();
			ajaxRequest = -1;
		}
		clearTimeout(previewTimer);
		previewTimer = setTimeout(generatePreview, 750);
	};

	// hotspot template selected handler
	var hotspotTemplateSelected = function (template) {
		var $zoomView = $(zoomViewSelector);
		$zoomView.removeClass('customizing').addClass('customizing');
		showGuide = true;
	};


	// apply preview handler
	// this is just like the generatePreview method, except this one saves a preview out to disk
	var applyPreview = function () {
		// this posts the relevant data to generate a hotspot preview
		var data = {
			BuildID: viewData.BuildID,
			ProjectID: viewData.ProjectID,
			FormState: JSON.stringify(hotspotFormStateReader.readHotspotFormState()),
			ShowGuide: showGuide,
			savePreview: true
		};
		http.post('/Build/Project/OneViewPreview', data).error(errorHandler);
	};

	// generate preview method
	// this calls the ajax endpoint to generate a preview
	var generatePreview = function () {
		// show the loading div at the top
		$(previewSelector).removeClass('loading').addClass('loading');
		var data = {
			BuildID: viewData.BuildID,
			ProjectID: viewData.ProjectID,
			FormState: JSON.stringify(hotspotFormStateReader.readHotspotFormState()),
			ShowGuide: showGuide
		};
		ajaxRequest = http.post('/Build/Project/OneViewPreview', data).error(errorHandler).done(previewGenerated);
	};

	// error handler for image generation ajax calls
	var errorHandler = function () {
		$(previewSelector).removeClass('loading');
		//alert('An error occurred while generating the preview.  An error notification \nhas been sent.  Please check your settings for any issues.');
	};

	// handles the response from the hotspot preview action, which is a base 64 byte array of the preview image
	var previewGenerated = function (response) {
		var $zoomView = $(zoomViewSelector);
		var template = getCurrentTemplate();
		// need to come back to this code and figure out what it does
		if (template != null) {
			if (template.FieldGuideImage && (template.FieldGuideImage != '')) {
				$zoomView.removeClass('has-guide').addClass('has-guide');
				bottomBar.guideAvailable();
				var $showGuide = $(showGuideLink);
				if (showGuide) {
					$showGuide.html('Hide Guide');
					bottomBar.guideDisabled();
				}
				else {
					$showGuide.html('Show Guide');
					bottomBar.guideEnabled();
				}
			}
			else {
				$zoomView.removeClass('has-guide');
				bottomBar.guideUnavailable();
			}
		}
		else
			$zoomView.removeClass('has-guide');
		// apply the response to the preview div background
		$(previewSelector).removeClass('loading');
		var $previewImage = $(previewImageContainer);
		$previewImage.css('background-image', 'url(\'data:image/png;base64,' + response + '\')');
		ajaxRequest = -1;
	};

	// preview button doesn't exist anymore and this method should be called automatically, or simply deprecated altogether
	var previewButtonClicked = function () {
		var $this = $(this);
		var openTabSelector = '.menu .hotspot-view.open .menu-tab.open';
		if ($this.hasClass('use-design')) {
			$(openTabSelector).removeClass('open');
			$('.menu .hotspot-view.open .menu-tab.design').removeClass('open').addClass('open');
		}
		else if ($this.hasClass('use-text')) {
			$(openTabSelector).removeClass('open');
			$('.menu .hotspot-view.open .menu-tab.text').removeClass('open').addClass('open');
		}
		else if ($this.hasClass('use-upload')) {
			$(openTabSelector).removeClass('open');
			$('.menu .hotspot-view.open .menu-tab.upload').removeClass('open').addClass('open');
		}
	};

	// get the current perspective that is being used
	var getCurrentPerspective = function () {
		return currentPerspective;
	};

	// get the current hotspot that is being used
	var getCurrentHotspot = function () {
		return currentHotspot;
	};

	// get the current template that is being previewed
	var getCurrentTemplate = function () {
		var formState = hotspotFormStateReader.readHotspotFormState();
		var template = loadedTemplates.getTemplate(formState.TemplateID);
		return template;
	};

	var initGuide = function () {
		showGuide = true;
	};

	return {
		init: init,
		initGuide: initGuide,
		getCurrentPerspective: getCurrentPerspective,
		getCurrentHotspot: getCurrentHotspot,
		getCurrentTemplate: getCurrentTemplate
	};
});
//// module that accesses the template data api
//define('oneViewCustomizetemplateData', ['http', 'viewData'], function (http, viewData) {
//	'use strict';

//	// get the templates associated with the hotspot template group
//	var getTemplatesForHotspotTemplateGroup = function (id) {
//		var params = {
//			templateGroupID: id
//		};
//		return http.get('/api/projects/perspectives/hotspots/groups/templates/list', params).fail(serviceError).then(processResponse);
//	};

//	// get the specific hotspot template for the id
//	var getTemplate = function (id) {
//		var params = {
//			id: id
//        };
//        alert(1);
//        return http.get('/api/projects/perspectives/hotspots/groups/templates/details', params).fail(serviceError).then(processResponse);
//	};

//	// handle an error when calling the api
//	var serviceError = function (error) {
//		//alert(error);
//	};

//	// process the response from the data api
//    var processResponse = function (response) {
//        alert(viewData)
//		return response.Data;
//	};

//	return {
//		getTemplatesForHotspotTemplateGroup: getTemplatesForHotspotTemplateGroup,
//		getTemplate: getTemplate
//	};
//});
// module that handles initializing one view project builds when the customer is editing an existing build
define('oneViewInitializer', ['viewData', 'jquery', 'templateData', 'messaging', 'loadedTemplates', 'templateForm', 'hotspotPreview', 'imageData', 'underscore', 'imageSelectorFieldControl', 'http'],
    function (viewData, $, templateData, messaging, loadedTemplates, templateForm, hotspotPreview, imageData, _, imageSelectorFieldControl, http) {
	'use strict';

	//// initialize the private variables
	//var previewTimer = 0;



	//// request the template data for the build in question
	//var init = function () {
	//	var templateID = viewData.OneViewTemplateID;

	//	// make the template data request
	//	templateData.getTemplate(templateID).done(templateGrabbed);

	//	messaging.hotspotPreviewRequested.subscribe(hotspotPreviewRequested);

	//	messaging.addToCartButtonClicked.subscribe(addToCartButtonClicked);
	//};

	//// template data api response handler
	//var templateGrabbed = function (template) {
 //       // load the template into the library and set the department temaplte price
 //       template.Price = viewData.SavedBuild.Hotspots[0].Template.Price;
 //       template.Price2 = viewData.SavedBuild.Hotspots[0].Template.Price2;
	//	loadedTemplates.loadTemplate(template);

	//	// if the template has a field guide image, initialize it
	//	if (template.FieldGuideImage)
	//		hotspotPreview.initGuide();

 //       var $templateFormContainer = $('.project-ui .template-form');

 //       // added for responsive
 //       $('.project-ui').addClass('active-fields');

	//	// initialize template form
	//	templateForm.initializeForm($templateFormContainer, template);

	//	// if includes a saved build, initialize the values
	//	if (viewData.SavedBuild) {
	//		var hotspot = viewData.SavedBuild.Hotspots[0];
	//		hotspot.Fields.forEach(function (buildField) {
	//			var field = _.find(template.Fields, function (field) { return field.UUID === buildField.UUID; });
	//			var option = _.find(field.FieldOptions, function (option) { return option.UUID === buildField.ActiveOptionUUID; });
	//			initializeField(buildField, field, option);
	//		});

	//		// apply the hotspot
	//		$('.hotspot-view').addClass('applied');

	//		// publish a hotspot preview request message
	//		messaging.hotspotPreviewRequested.publish();

	//		// this forces the notes to change in the review pane
 //           $('.field-notes textarea').change(hotspotPreviewRequested);

 //           // if there is at least 1 hotspot applied than show the review content instead of the project help text
 //           if ($('.hotspot-content-container .hotspot-view.applied').length > 0) {
 //               $('.menu .template-form').addClass('hide');
 //               $('.menu .navigation-wrapper').addClass('hide');
 //               $('.menu .review-container').removeClass('hide');
 //           }

	//	}
	//	else {
	//		// apply the hotspot
	//		$('.hotspot-view').addClass('applied');

	//		// publish a hotspot preview request message
	//		messaging.hotspotPreviewRequested.publish();

	//		// this forces the notes to change in the review pane
 //           $('.field-notes textarea').change(hotspotPreviewRequested);

 //       }
 //       // hide back button in first field
 //      // $('.hotspot-view.open .navigation-wrapper .field-nav-button.back, .hotspot-view.open .navigation-wrapper .field-nav-button.next').addClass('one-view-first-field');

	//};

	//// applies the field data for a hotspot to the hotspot form inputs
	//var initializeField = function (buildField, field, option) {
	//	var $field = $('.template-form .field[data-id=' + buildField.UUID + ']');
	//	var $fieldOption = $field.find('.field-option[data-id=' + buildField.ActiveOptionUUID + ']');
	//	$field.find('.field-option-select').val(buildField.ActiveOptionUUID).change();
	//	if ([2, 3, 4, 5, 6, 7, 8, 9, 13, 14].indexOf(buildField.Type) != -1) {	// text field type
	//		$fieldOption.find('.text-format-select').val(buildField.SelectedOption.ActiveFormatUUID).change();
	//		$fieldOption.find('.Text-field input').val(buildField.Text).change();
	//		$fieldOption.find('.Color-field input').val(buildField.ColorID).change();
	//		$fieldOption.find('.OutlineColor-field input').val(buildField.OutlineColorID).change();
	//		$fieldOption.find('.ShadowColor-field input').val(buildField.ShadowColorID).change();
	//	}
	//	else if (buildField.Type == 1) {	// image field type
	//		// if imageid is 0, then no image is selected
	//		if (buildField.ImageID != 0)
 //           {
 //               $field.addClass('saved-build-first-view');
 //               // in order to properly apply the image, it must be retrieved from the image data api
 //               imageData.getImage(buildField.ImageID).done(function (image) {
 //                   // apply the selected image to the field
 //                   imageSelectorFieldControl.applySelectedImageToField($field.find('.field-option-image-control'), image);
 //                   // loop over each replaced color and initialize the value
 //                   buildField.ReplacedColors.forEach(function (replacedColor) {
 //                       var replaceableColorID = replacedColor.ID;
 //                       var colorID = replacedColor.ColorID;
 //                       $fieldOption.find('.field-option-image-color-control[data-replaceableid=' + replaceableColorID + '] input[type=hidden]').val(colorID).change();
 //                       $fieldOption.find('.Image-field').addClass('read-only');
 //                   });
 //               });      
	//		}
	//	}
	//	else if (buildField.Type === 11) {// custom select option
	//		buildField.SelectedOptions.forEach(function (selectedCustomOption) {
	//			$fieldOption.find('input[data-sku=' + selectedCustomOption.Sku + ']').prop('checked', true);	
	//		});
 //       }
 //       else if (buildField.Type === 12) {// custom image         
 //           $fieldOption.find('.custom-image-content-wrapper input.custom-image-id').val(buildField.CustomImage.CloudinaryId);
 //           $fieldOption.find('.custom-image-content-wrapper input.custom-image-version').val(buildField.CustomImage.Version);
 //       }
	//};

	//// hotspot preview requested subscription handler
	//var hotspotPreviewRequested = function () {
	//	clearTimeout(previewTimer);
	//	// wait a specific amount of time before publishing an apply button click message
	//	previewTimer = setTimeout(function () {
	//		messaging.applyButtonClicked.publish();
	//	}, 1000);
	//};

	//// add to cart click handler
	//var addToCartButtonClicked = function () {
	//	messaging.applyPreview.publish();
	//};

	//return {
	//	init: init
	//};
});
require(['hotspotPreview', 'menu', 'templateForm', 'review', 'templateGroups', 'loadedTemplates', 'imageSelectorFieldControl', 'addToCart', 'applyHotspot', 'oneViewInitializer', 'commonColorClick', 'buildTemplateInitializer', 'overlay'], function
    (hotspotPreview, menu, templateForm, review, templateGroups, loadedTemplates, imageSelectorFieldControl, addToCart, applyHotspot, oneViewInitializer, commonColorClick, buildTemplateInitializer, overlay) {
    'use strict';

    var init = function () {
        // add overlay to prevent unwanted clicks
        overlay.addOverlay();
        setTimeout(function () {
            overlay.removeOverlay();
        }, 2000);


        // run init functions for relevant modules
        review.init();
        menu.init();
        hotspotPreview.init();
        templateGroups.init();
        templateForm.init();
        loadedTemplates.init();
        imageSelectorFieldControl.init();
        addToCart.init();
        applyHotspot.init();
        applyHotspot.disableValidation();

      

        // initialize the saved build
        buildTemplateInitializer.initializeBuildTemplate();     

        review.refreshReviewUI();

       // oneViewInitializer.init();

    };

    init();
});